import { useState, useCallback } from 'react';

import esigSigned from '../../images/esig-signed-document.jpg';
import close from '../../images/close-content.png';
import img1 from '../../images/signed-doc-ds.jpg';
import img2 from '../../images/signed-doc-ff.jpg';
import img3 from '../../images/signed-doc-es.jpg';
import img4 from '../../images/esig-signed-document.jpg';

const Sample = () => {

  const [visibility, setVisibility] = useState(0);
  const toggleVisibility = useCallback((v) => {
    setVisibility((visibility) => {
      return visibility === v ? 0 : v;
    });
  },[setVisibility]);

  return (
    <section className="sample">
      <div className="container pb-5">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>CREATE EASY-TO-VERIFY PDF DOCUMENTS</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <p className="mb-4">
              We eliminated the need for personal signing certificates or chip
              cards and PINs. By signing the screen of a tablet or a signature
              pad, you are able to identify your client and instantly bind the
              signature to the document displayed on the very same screen. This
              guarantees the highest level of customer confidence. We can also
              integrate other user identification methods, such as smartcards,
              fingerprint scanners and palm vein scanners.
            </p>
          </div>
        </div>
        <div className="full-image signed-doc">
            <div
              className="signed-content signed-content-ds"
              hidden={visibility !== 1}
            >
                <div
                  className="signed-content-close"
                  onClick={() => toggleVisibility()}
                >
                    <img src={close} alt="Close content" />
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <img src={img1} alt="Digital Seals" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="signed-blue-box">
                            <ul>
                                <li>
                                  The free Adobe Acrobat Reader tool will make
                                  visible the digital seals and timestamps
                                  embedded in the signed PDF.
                                </li>
                                <li>
                                  The digital seals and the green checkmarks
                                  together verify the integrity of the
                                  document’s content and the origin of the
                                  document itself.
                                </li>
                                <li>
                                  All the changes that took place in the
                                  document before and after each electronic
                                  signature can be traced here.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
              className="signed-content signed-content-ff"
              hidden={visibility !== 2}
            >
                <div
                  className="signed-content-close"
                  onClick={() => toggleVisibility()}
                >
                    <img src={close} alt="Close content" />
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <img src={img2} alt="Form Field" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="signed-blue-box">
                            <ul>
                                <li>
                                  The signer is able to give explicit consent
                                  by ticking boxes, choosing between options
                                  with radio buttons or even filling in text
                                  boxes during the signing process.
                                </li>
                                <li>
                                  With the usage of timestamps, the exact
                                  content of the document can be sealed at the
                                  very moment of placing the handwritten
                                  signature on it.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
              className="signed-content signed-content-es"
              hidden={visibility !== 3}
            >
                <div
                  className="signed-content-close"
                  onClick={() => toggleVisibility()}
                >
                    <img src={close} alt="Close content" />
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <img src={img3} alt="E-Signatures" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="signed-blue-box">
                            <ul>
                                <li>
                                  A visible image of the signature is displayed
                                  on the PDF, while the high-resolution
                                  biometric data, including speed and pressure
                                  is encrypted inside the document.
                                </li>
                                <li>
                                  Our patented process guarantees that an
                                  electronic signature cannot be stolen or
                                  reused on another document.
                                </li>
                                <li>
                                  Cursor Insight’s electronic signatures are
                                  truly undeniable and are more secure than
                                  paper based signatures.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <button
              className="click-here-ds"
              onClick={() => toggleVisibility(1)}
            >
            </button>
            <button
              className="click-here-ff"
              onClick={() => toggleVisibility(2)}
            >
            </button>
            <button
              className="click-here-es"
              onClick={() => toggleVisibility(3)}
            >
            </button>
            <img src={img4} alt="A signed document" />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="text-center mt-5">
              <a href="mailto:signowise@cursorinsight.com" className="btn">
                Request a Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sample;
