import React from 'react';

import Header from '../sections/header/Header';
import Footer from '../sections/footer/Footer';

import Hero from '../sections/hero/Hero';
import Benefits from '../sections/benefits/Benefits';
import Solutions from '../sections/solutions/Solutions';
import Prices from '../sections/prices/Prices';
import Verification from '../sections/verification/Verification';
import About from '../sections/about/About';
import Sample from '../sections/sample/Sample';
import Faq from '../sections/faq/Faq';

const IndexPage = () => (
  <>
    <Header />
    <main>
      <Hero />
      <Benefits />
      <Solutions />
      <About />
      <Prices />
      <Sample />
      <Verification />
      <Faq />
    </main>
    <Footer />
  </>
);

export default IndexPage;
