import { Link } from 'react-scroll';
import logoBlack from '../../images/logo-black.svg';
import arrowBtn from '../../images/arrow-btn.svg';
import video from '../../signo_wise.mp4';

const Hero = () => {
  return (
    <section className="hero">
      <video className="video" autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={logoBlack} className="logo" alt="logo" />
            <div className="hero__text">
              <h3>Biometric e-signature</h3>
              <h4>More than 4 million users</h4>
              <ul>
                <li>Save up to 10 minutes and EUR 3 per document with paperless contracting.</li>
                <li>Over 4 million users, including contracting clients of SMEs and large banks as well.</li>
                <li>Signing on a tablet feels the same as signing on paper. It makes client transition to paperless seamless.</li>
              </ul>
              <a
                className="btn"
                href="mailto:info@cursorinsight.com?subject=SignoWise generic demo request"
              >
                Schedule a demo <img src={arrowBtn} alt="arrow-btn" />
              </a>
            </div>
          </div>
          <div className="col-lg-6">
          </div>
        </div>
        <div className="row">
          <Link to="benefits"
            smooth={false}
            offset={-100}
            className="scroll"
            isDynamic={true}
          >
            <div className="scroll-downs">
              <div className="mousey">
                <div className="scroller"></div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Hero;
