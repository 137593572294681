import { useEffect } from 'react';
import logo from '../../images/logo.svg';
import jQuery from 'jquery';

const Header = (props) => {
  useEffect(() => {
    const toggler = jQuery('.navbar-toggler');
    const navbar = jQuery('.navbar-collapse');
    jQuery('nav a').on('click', () => {
      if (toggler.css('display') !== 'none') {
         if (navbar.css('display') !== 'none') {
           toggler.trigger('click');
         }
      }
    });
  }, []);

  return (
    <header>
      <div className="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto align-items-center">
              <a className="btn" href="mailto:info@cursorinsight.com">
                Schedule a demo
              </a>
              <div class="dropdown">
                <button class="dropbtn">Integrated Solutions
                  <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                  <div class="column">
                    <h5>Use Cases</h5>
                    <a href="#">Money transfers</a>
                    <a href="#">Retail banking use cases</a>
                    <a href="#">Insurance in use cases</a>
                    <a href="/econtracting">E-contracting</a>
                    <a href="#">Financing, lending & mortgages</a>
                    <a href="#">Commercial banking use cases</a>
                    <a href="#">Human resources</a>
                    <a href="#">E-prescribing</a>
                  </div>
                  <div class="column">
                    <h5>Industries</h5>
                    <a href="#">Financial services</a>
                    <a href="#">Insurance</a>
                    <a href="#">Lending and Financing</a>
                    <a href="#">Retail Banking</a>
                    <a href="#">Commercial Banking</a>
                    <a href="#">Wealth Management</a>
                    <a href="#">Government</a>
                    <a href="#">Healthcare</a>
                  </div>
                </div>
              </div>
              <a
                href="mailto:info@cursorinsight.com"
                className="link"
              >
                Contact
              </a>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
