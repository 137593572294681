import {Accordion, Tab, Tabs} from 'react-bootstrap';

const Faq = () => {
  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>
                Frequently Asked Questions
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="faq__inner">
              <Tabs defaultActiveKey="how-to" className="faq__inner--header">
                <Tab eventKey="how-to" title="What's my benefit?">
                  <h5>{' '}</h5>
                  <Accordion>
                     <div className="faq__inner--line">
                        <Accordion.Item eventKey="0"><Accordion.Header>
                            How can I spare up to 3 EUR and 10 minutes per document? Or Why is SignoWise worth it?
                        </Accordion.Header></Accordion.Item>
                        <Accordion.Collapse eventKey="0" className="answer">
                          <div>
                            <p>True digitalization starts with going paperless. Paperless starts with e-signatures.</p>
                            <p>Benefits are numerous and large</p>
                            <p>Wage savings: if a printing, filing takes only 2 minutes, that means EUR 0,2 per document assuming a low EUR 1000 gross wage per month.</p>
                            <p>If you need to type in data as well from paper into your systems, that is a further EUR 0,3 per document assuming the same wage.</p>
                            <p>Paper and printing material: EUR 0,02 per page</p>
                            <p>Faster processes, better sales conversion: in case of a sales process, this is crucial. Electronic documents travel with the speed of light, your back office or HQ can take over the process instantly and reply / approve. This dramatically speeds up the processes. With paper documents you have to wait for the transport of the document and potentially schedule another meeting reducing your chance to close the deal dramatically.</p>
                            <p>Less errors: No more mismatches between data on your paper documents and in your systems. Data cannot be overwritten in an electronic document. Furthermore, automatic data validations can be performed.</p>
                            <p>Baseline for automatic processes: electronic documents are a baseline for fully automatic processes. Automatic steps may include: automatic archiving, storage, data input (such as deal parameters, client preferences) and automatic data validation.</p>
                            <p>Client experience and environment protection: clients do appreciate your environment protection and not without merit. Paper and ink production are among the top polluters and CO2 emitter industries.</p>
                          </div>
                        </Accordion.Collapse>

                    </div>
                   <div className="faq__inner--line">
                      <Accordion.Item eventKey="1"><Accordion.Header>
                        What companies benefit the most by using SignoWise?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="1" className="answer">
                        <div>
                          <p>Those having large number of signing partners. The more the documents and partners, the larger the benefit.</p>
                          <p>In case of few signing partners, qualified electronic signature may be considered.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="aliro-partnereimnek-miert-jo-a-signowise"><Accordion.Header>
                        Why is SignoWise good for my signing partners?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="aliro-partnereimnek-miert-jo-a-signowise" className="answer">
                        <div>
                          <p>Signing with SignoWise is intuitive.</p>
                          <p>Your signing partner's only prerequisite is to be able to write - no need to make any preparations, registrations, authentications, unlike qualified electronic signatures.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </Tab>
                <Tab eventKey="law" title="Legal compliance">
                  <Accordion>
                    <h5>Layman's legal summary</h5>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="0"><Accordion.Header>
                        Why do I trust SignoWise's legal compliance?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="0" className="answer">
                        <div>
                          <p>OTP Bank (largest bank in Hungary), DSK Bank (largest bank in Bulgaria) and the Hungarian Notary Chamber are among our clients. Our banking solution is audited by the National Bank. Our clients signers produced tens of millions of documents, we have 2+ million users. The development of the SignoWise solution was a multi year process involving numerous experts from various fields.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="1"><Accordion.Header>
                        How can I use SignoWise properly?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="1" className="answer">
                        <div>
                          <p>Great question. Any e-signature solution needs certain knowledge and none is good regardless of conditions.</p>
                          <p>You have two tasks: </p>
                          <ol>
                            <li>Contracting legal form. All contracts must be conform to a certain legal form. Most countries have only a few formats. SignoWise produces the written format, which is the broadest format in use for most EU countries.</li>
                            <li>You have to make sure, that you need this format. It is commonly used in banking, healthcare and most other industries. You only have to do this once per contract type, no worries.</li>
                            <li>Identifying your signer. This is your task using paper contracts or any e-signatures, and it is compulsory. Proper identification process is described by the law governing your contract. In general, you have to follow the same process as if you sign a paper contract. If you could do it before, you can still do it.</li>
                            <li>In summary: your document must contain the proper signer data, just like your paper documents do. The legal background of written contracts are the same for physical paper documents and for SignoWise documents.</li>
                          </ol>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="2"><Accordion.Header>
                        Why do I have to identify my signers?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="2" className="answer">
                        <div>
                          <p>Identifying the contracting parties is always compulsory part of any contract singing. You have to do in all cases even on paper.</p>
                          <p>Wait: qualified e-signatures are proving the identity of the signer. Are they better? Well, they do prove the signer's identity, however, you still need to identify your signer. Why? Because you have to check whether the signer is indeed who you want to sign with. It is for example a common real estate fraud, that similary named fraudsters sell someone else's property and run with the money. If you do not check it is your fault and damage.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="3"><Accordion.Header>
                        What if someone draws a nice kitten into the signature field? :)
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="3" className="answer">
                        <div>
                          <p>Same as if she/he would do it on your paper contract: you compliment her/his creativity and ask to sign properly.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Court process</h5>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="15"><Accordion.Header>
                        What if someone attacks a SignoWise document on court?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="15" className="answer">
                        <div>
                          <p>Process is very similar to that of a physical paper document. The document, along with other pieces of evidence are handed over to the court. If the judge deems it necessary, the detailed biometric signature data can be decrypted and examined by a forensic expert.</p>
                          <p>No solution is bulletproof. It depends on the usage. SignoWise is capable to provide better security than paper alternatives if used properly. For example, all Hungarian Notaries are using the solution, that is only possible if it adheres to the strictest standards. However if it is misused, than it is less secure, but that is true for any alternative.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>IT Security</h5>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="18"><Accordion.Header>
                        How is a document protected?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="18" className="answer">
                        <div>
                          <p>The document is cryptographically sealed right after the signature. That means it cannot be amended in any way. It also contains inseparably the encrypted biometric signature data and a timestamp.</p>
                          <p>Checking the document integrity can be easily done by many software tools, such as Adobe Acrobat.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="19"><Accordion.Header>
                        How do you protect the signature data?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="19" className="answer">
                        <div>
                          <p>The detailed biometric signature data is inseparably added to the document. It is encrypted with a separated encryption key, that is deposited at Microsec Zrt, a qualifyed Trust Service Provider. Accessing the data is logged and can be done only with permission from the client.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="20"><Accordion.Header>
                        How is the Internet communication protected?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="20" className="answer">
                        <div>
                          <p>Communication is done via secure and ecrypted HTTPS protocol. Both parties are authenticated with specific API tokens.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Data protection</h5>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="21"><Accordion.Header>
                        Is SignoWise GDPR compliant?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="21" className="answer">
                        <div>
                          <p>Of course. Key points:</p>
                          <p>you have to inform your signing parties of using SignoWise in your data protection policy.</p>
                          <p>You are the data handler, while Cursor Insight is a data processor.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="22"><Accordion.Header>
                        How long is the document stored on SignoWise servers?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="22" className="answer">
                        <div>
                          <p>SignoWise hands the signed document back right after signing is over. The whole session is then deleted along with the original and the final signed document.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </Tab>
                <Tab eventKey="gyik" title="Technical guide">
                  <h5>{' '}</h5>
                  <Accordion>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="0"><Accordion.Header>
                        How is the SignoWise system working?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="0" className="answer">
                        <div>
                          <p>SignoWise expects receiving a PDF format document ready to sign, complete with stanrard PDF signature fields. This document is usually provided by company document management system.</p>
                          <p>The final signed document is handed by cryptographically sealed to the company archiving system.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="1"><Accordion.Header>
                        What is included in the SignoWise solution?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="1" className="answer">
                        <div>
                          <p>SignoWise is a complete biometric e-signature solution. It contains the necessary qualifyed timestamp, electronic seal certificate. This enables anyone to check the integrity of the document easily by simple tools, such as Adobe Acrobat.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line">
                      <Accordion.Item eventKey="2"><Accordion.Header>
                        How do I integrate SignoWise?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="2" className="answer">
                        <div>
                          <p>Basicly you need the following integration points:</p>
                          <p>1. Sending documents to SignoWise for signature.</p>
                          <p>2. Initiating the signature for a document.</p>
                          <p>3. Building a function so that SignoWise can send back the ready document.</p>
                          <p>For details, please refer to our technical integration guide.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
