const Prices = () => {
  return (
    <section className="prices" id="prices">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>
                Competitive and flexible plans
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <p>
                <strong>
                  Signing only a few documents a month or operating a network
                  of hundreds of branches?
                  <br />
                  Find the pricing plan that suits your need.
                </strong>
              </p>
              <ul>
                <li>
                  SignoWise Cloud webservice-based biometric signatures for 20
                  to 2,000 signatures per month: subscribe from
                  EUR 79 + VAT / month plus EUR 0.5 +VAT per e-signature.
                </li>
                <li>
                  SignoWise Enterprise on-premise biometric e-signature solution
                  for 1,000 to 5,000 active signature devices: license for
                  EUR 10 + VAT / month per signature device.
                </li>
                <li>
                  Add our award-winning Signature Verification module to
                  SignoWise Enterprise to get a real-time verification of the
                  hand-written signatures. Available from EUR 3 + VAT / month
                  per signature device.
                </li>
              </ul>
              <p>
                End-to-end integration, flexible support plans with industry
                leading SLAs and special license plans for larger operations.
                <br />
                <a href="mailto:info@cursorinsight.com">Get in touch now</a>
                {' '}
                to discuss your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Prices;
