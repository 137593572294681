import React from 'react';

import Header from '../../sections/header/Header';
import Footer from '../../sections/footer/Footer';
import Sample from '../../sections/sample/Sample';
import About from '../../sections/about/About';
import Faq from '../../sections/faq/Faq';

import benefit1 from '../../images/esig-save-time.svg';
import benefit2 from '../../images/esig-save-money.svg';
import benefit3 from '../../images/esig-legal.svg';
import benefit5 from '../../images/authentication-eliminate.svg';
import judge from '../../images/judge.svg';
import risk from '../../images/risk.svg';
import eco from '../../images/eco.svg';
import dfk from '../../images/dfk.png';
import balabit from '../../images/balabit.png';
import company from '../../images/b2b.svg';
import legal from '../../images/legal.svg';
import contract from '../../images/procurement.svg';

const Econtracting = () => (
  <>
    <Header />
    <main className="econtracting">
      <div className="container">
        <div className="row my-lg-5 mb-5">
          <div className="col-12 text-center">
            <h1>Signature Verification</h1>
            <div className="py-3 mb-5">
              <h4>
                World leaders in signature verification,
                <br />
                discover how you can utilize our technology
              </h4>
            </div>
            <div>
              <h1>E-contracting</h1>
              <h4 className="py-3 mb-4">
                Revolutionize your e-contracting processes
                <br />
                and enjoy increased efficiency, productivity,
                <br />
                and security with SignoWise
              </h4>
            </div>
          </div>
          <div className="col-12 text-center mb-3 buttons">
            <a className="btn" href="mailto:info@cursorinsight.com">
              Request demo
            </a>
            <a className="btn" href="mailto:info@cursorinsight.com">
              Contact
            </a>
          </div>
        </div>
      </div>
      <div className="awards">
        <div className="container">
          <div className="row my-5">
            <div className="col-12 text-center">
              <h1>Awards</h1>
              <div className="d-lg-flex">
                <div>
                  <img src={dfk} />
                  <p>Winner of SigWiComp by the German Research Center of AI</p>
                </div>
                <div>
                  <img src={balabit} />
                  <p>
                    Winner of the Mouse Dynamics Challenge competition of
                    Balabit
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container challenge">
        <div className="row my-5 cards">
          <div className="col-12 text-center">
            <h1>Business challenge</h1>
            <h5>
              The lack of e-contracts supported by e-signature verification
              raises the following issues:
            </h5>
          </div>
          <div className="col-lg-4 col-md-12 text-center card">
            <img src={judge} />
            <h3>Compliance challenges</h3>
            <p>
              Without e-contracts, it can be harder to implement and enforce
              compliance measures, exposing the company to potential legal
              risks and non-compliance penalties.
            </p>
          </div>
          <div className="col-lg-4 col-md-12 text-center card">
            <img src={risk} />
            <h3>Higher risks</h3>
            <p>
              Paper contracts are susceptible to physical damage, loss, or
              misplacement. Additionally, the chances of fraud are higher
              and cannot be detected immediately.
            </p>
          </div>
          <div className="col-lg-4 col-md-12 text-center card">
            <img src={eco} />
            <h3>Eco-friendliness</h3>
            <p>
              Relying on paper contracts contributes to increased paper waste
              and environmental impact, which may negatively affect the
              company's reputation.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div class="row mb-lg-5 text-center cards">
          <h1>Why SignoWise?</h1>
          <h5 className="mt-3 mb-5">
            Make sure you can trust the identity of your digital partners
          </h5>
          <div class="col-lg-6 col-12 card">
            <img src={benefit5} alt="Eliminate fraud"/>
            <h3>Eliminate Fraud </h3>
            <p>
              Content of signed documents cannot be manipulated. Our process
              prevents the theft or the re-use of a signature that was placed
              on a document.
            </p>
          </div>
          <div class="col-lg-6 col-12 card">
            <img src={benefit3} alt="Legal Compliance"/>
            <h3>Legal Compliance</h3>
            <p>
              Legally binding electronic signatures compliant with the EU’s
              eIDAS regulations.
            </p>
          </div>
        </div>
        <div class="row mb-5 cards">
          <div class="col-lg-6 col-12 card">
            <img src={benefit2} alt="Save Money"/>
            <h3>Save Money</h3>
            <p>
              Spend less on paper &amp; printing, filing, transporting,
              archiving, storing and destroying paper documents.
            </p>
          </div>
          <div class="col-lg-6 col-12 card">
            <img src={benefit1} alt="Save Time"/>
            <h3>Save Time</h3>
            <p>
              No more printing, stapling and hand-signing of multiple copies.
              We integrate into existing document management systems.
            </p>
          </div>
        </div>
      </div>
      <Sample />
      <About />
      <div className="usecases">
        <div className="container">
          <div className="row my-5 cards">
            <div className="col-12 text-center mb-5">
              <h1>Designed to a wide array of use cases</h1>
            </div>
            <div className="col-lg-4 text-center">
              <div className="card">
                <img src={company} />
                <h3>B2B Sales</h3>
                <ul>
                  <li>Sales/Reseller agreements</li>
                  <li>Contract renewals</li>
                  <li>Non-disclosure agreements</li>
                  <li>Statement of Work</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="card">
                <img src={legal} />
                <h3>Legal</h3>
                <ul>
                  <li>Affidavits</li>
                  <li>Company documents</li>
                  <li>Non-disclosure agreements</li>
                  <li>Retainer agreements</li>
                  <li>Settlements</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="card">
                <img src={contract} className="contract" />
                <h3>Procurement</h3>
                <ul>
                  <li>Master service agreement</li>
                  <li>Purchase orders</li>
                  <li>Service-level agreement</li>
                  <li>Supplier compliance</li>
                  <li>Vendor agreements</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits">
        <div className="container">
          <div className="row my-5 pt-5">
            <div className="col-12 text-center">
              <h1>Even more benefits</h1>
              <div className="row mt-5">
                <div className="col-lg-4 col-sm-12 mb-5">
                  <span>1</span>
                  <h3>Excellence</h3>
                  <p>
                    Our verification technology regularly reaches a
                    cross error rate (CER) below 1% in blind forgery tests.
                  </p>
                </div>
                <div className="col-lg-4 col-sm-12 mb-5">
                  <span>2</span>
                  <h3>Scalability</h3>
                  <p>
                    The solution scales easily and can process millions
                    of profiles and thousands of simultaneous verification requests.
                  </p>
                </div>
                <div className="col-lg-4 col-sm-12 mb-5">
                  <span>3</span>
                  <h3>Identification</h3>
                  <p>
                    Handwriting and signatures are fingerprint-like
                    biometric identifiers. And unlike your password or fingerprint,
                    fine motor movement patterns cannot be imitated or stolen.
                  </p>
                </div>
                <div className="col-lg-4 col-sm-12 mb-5 offset-lg-2">
                  <span>4</span>
                  <h3>Thoroughness</h3>
                  <p>
                    Our proprietary algorithm has excellent results
                    both in random and skilled signature forgery tests.
                  </p>
                </div>
                <div className="col-lg-4 col-sm-12 mb-5">
                  <span>5</span>
                  <h3>Security</h3>
                  <p>
                    The verification system uses the instantly encrypted
                    biometric features of captured signatures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Faq />
    </main>
    <Footer />
  </>
);

export default Econtracting;
