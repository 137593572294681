import React from 'react';
import ReactDOM from 'react-dom';

import './App.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

TagManager.initialize(tagManagerArgs);

// Add Graboxy Captcha <script> tag
const scriptUrl = process.env.REACT_APP_GRABOXY_CAPTCHA_SCRIPT_URL;
const scriptElement = document.createElement('script');
scriptElement.setAttribute('src', scriptUrl);
document.head.appendChild(scriptElement);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
