import benefit1 from '../../images/esig-save-time.svg';
import benefit2 from '../../images/esig-save-money.svg';
import benefit3 from '../../images/esig-legal.svg';
import benefit4 from '../../images/esig-biometric.svg';
import benefit5 from '../../images/authentication-eliminate.svg';
import benefit6 from '../../images/esig-communicate.svg';

const Benefits = () => {
  return (
    <section id="benefits">
      <div class="container">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>
                DIGITISE DOCUMENTS TO REDUCE COSTS
              </h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-center">
              Go paperless while improving customer confidence and your bottom
              line at the same time with Cursor Insight’s SignoWise Biometric
              Electronic Signature system. It works with iOS and Android
              tablets, tablet PCs and dedicated signature pads. Trusted by our
              clients for whom we process tens of millions of e-signatures every
              year, saving them and their customers valuable minutes at every
              single transaction. Integrate it to your document management
              system or sign directly from MS Word.
            </p>
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                  {' '}
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <div class="benefits-line">
                    <div class="benefits-vertical-line-left"></div>
                    <div class="benefits-vertical-line-center"></div>
                    <div class="benefits-vertical-line-right"></div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                  {' '}
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <img src={benefit1} alt="Save Time" class="key-benefit-image" />
                    <h3> Save Time </h3>
                    <p> No more printing, stapling and hand-signing of multiple copies. We integrate into existing document management systems. </p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <img src={benefit2} alt="Save Money" class="key-benefit-image" />
                    <h3> Save Money </h3>
                    <p> Spend less on paper &amp; printing, filing, transporting, archiving, storing and destroying paper documents. </p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <img src={benefit3} alt="Legal Compliance" class="key-benefit-image" />
                    <h3>Legal Compliance</h3>
                    <p>Legally binding electronic signatures compliant with the EU’s eIDAS regulations. </p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <img src={benefit4} alt="Biometric Verification" class="key-benefit-image" />
                    <h3>Biometric Verification </h3>
                    <p>Automatically verify the genuineness of signatures with our award winning technology.  It’s faster and more reliable than a typical human signature evaluator. </p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <img src={benefit5} alt="Eliminate fraud" class="key-benefit-image" />
                    <h3>Eliminate Fraud </h3>
                    <p>Content of signed documents cannot be manipulated. Our process prevents the theft or the re-use of a signature that was placed on a document. </p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 text-center">
                    <img src={benefit6} alt="Communicate and Merchandise" class="key-benefit-image" />
                    <h3>Communicate and Merchandise </h3>
                    <p>Display a custom slideshow on the device when in standby mode. Signed documents can be simply emailed to your clients. </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
