import esig from '../../images/esig-signotec.jpg';

const Verification = () => {
  return (
    <section className="verification">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>
                Verify handwritten signatures instantly
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <p>
                Our machine learning based Signature Verification service can
                be added to SignoWise or any 3<sup>rd</sup> party biometric
                electronic signature solution.
              </p>
              <p>
                Cursor Insight’s award winning verification system automatically
                scores digitally captured hand signatures of unique users based
                on the level of their authenticity. The automatic signature
                verification service is accessible by process control systems
                as a web service. The output is an “accept” / “reject” value
                based on a probability score describing how much the biometric
                features of the given signature resemble those of the reference
                signatures of the same client.
              </p>
              <ul>
                <li>
                  <strong>Security.</strong> The verification system uses the
                  instantly encrypted biometric features of captured signatures.
                  Decrypted data is only handled in the memory of the
                  verification server and won’t be saved in databases or
                  temporary files. Thus, the unencrypted biometric signatures
                  never leave the signature device or the secure verification
                  system.
                </li>
                <li>
                  <strong>Thoroughness.</strong> Our technology processes
                  x, y and time coordinates of signatures, as well as screen
                  pressure. It uses the data to calculate tens of thousands of
                  unique movement characteristics of every single signature.
                  Our proprietary algorithm has excellent results both in random
                  and skilled signature forgery tests. The algorithm combines
                  scientific principles of handwriting science, psychology,
                  physics and mathematics.
                </li>
                <li>
                  <strong>Excellence.</strong> Our team is the winner of
                  SigWIComp, the global signature verification competition
                  organised by the German Institute of Artificial Intelligence.
                  Our algorithm has surpassed the solutions of academic research
                  teams and also proved to be better than all the other
                  commercial technologies that competed. Our verification
                  technology regularly reaches a cross error rate (CER)
                  below 1% in blind forgery tests.
                </li>
                <li>
                  <strong>Scalability.</strong> The verification is fast, it
                  takes less than a second per signature. The solution scales
                  easily and can process millions of profiles and thousands of
                  simultaneous verification requests.
                </li>
                <li>
                  <strong>Identification.</strong> Handwriting and signatures
                  are fingerprint-like biometric identifiers. Fine motor
                  movements captured while moving the stylus provide a level
                  of uniqueness that cannot be exactly repeated. And unlike
                  your password or fingerprint, fine motor movement patterns
                  cannot be imitated or stolen.
                </li>
              </ul>
              <img src={esig} alt="Esig Signotec" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Verification;
