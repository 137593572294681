import Slider from 'react-slick';

import kisflavi from '../../images/kisfalvi_bence.jpeg';
import erdosi from '../../images/erdosi_peter.jpeg';
import bodnar from '../../images/bodnar_balazs.jpeg';
import dajkaViktor from '../../images/dajka_viktor.png';
import iconOtp from '../../images/icon-otp.png';
import iconOtpTwo from '../../images/icon-otp-two.png';
import iconAndoc from '../../images/icon-andoc.png';

const About = () => {

  const sliderSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <section className="about">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>
                TRUSTED BY LEADING ORGANIZATIONS
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <Slider {...sliderSettings}>
            <div className="card">
              <div className="card__image">
                <img src={kisflavi} alt="kisflavi bence" />
              </div>
              <div className="card__icon">
                <img src={iconOtpTwo} alt="OTP" />
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Bence Kisfalvi
                </p>
                <p className="card__text--description">
                  Director, business development
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  The Cursor Insight team delivered the SignoWise e-signature
                  system on time. The system is deemed "critical" and is used
                  across our 400 branches.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card__image">
                <img src={erdosi} alt="erdősi péter" />
              </div>
              <div className="card__icon">
                <p>Hungarian e-signature association</p>
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Péter Erdősi
                </p>
                <p className="card__text--description">
                  Vice-president
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  The SignoWise solution is a practical system based on smart
                  combination of biometric signature and PKI encryption
                  technology.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card__image">
                <img src={bodnar} alt="bodnár balázs" />
              </div>
              <div className="card__icon">
                <img src={iconOtp} alt="OTP" />
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Balázs Bodnár
                </p>
                <p className="card__text--description">
                  Head of Back Office
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  Our agents and clients use SignoWise reliably for years on
                  the field, on nearly 1000 tablets.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card__image">
                <img src={dajkaViktor} alt="Dajka Viktor" />
              </div>
              <div className="card__icon">
                <img src={iconAndoc} alt="Andoc" />
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Viktor Dajka
                </p>
                <p className="card__text--description">
                  Director, Operations
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  Integrating SignoWise to our Andoc document management system
                  was quick and seamless.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default About;
