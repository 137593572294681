import React from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import Econtracting from './pages/Econtracting';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/econtracting" element={<Econtracting />} />
    </Routes>
  </BrowserRouter>
);

export default App;
