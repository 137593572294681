import swWord from '../../images/signowise-enterprise.svg';
import swCloud from '../../images/sw-cloud.svg';
import biometric from '../../images/biometric-verification.svg';
import arrowBtn from '../../images/arrow-btn.svg';

const Solutions = () => {
  return (
    <section className="solutions" id="solutions">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title text-center">
              <h3>
                SOLUTIONS TAILORED FOR ALL NEEDS
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="solutions__box word">
              <div className="solutions__box--img">
                <img src={swCloud} alt="Signo Wise" />
              </div>
              <div className="solution-main">
                <h3>e-signature for SMEs</h3>
              </div>
              <div className="solution-list">
                <ul>
                  <li>
                    Ready to use e-signature solution for SMEs
                  </li>
                  <li>
                    Start the signing process from MS Word,
                    use any tablet to sign
                  </li>
                  <li>
                    Webservice based architecture that takes 1 day to integrate
                  </li>
                  <li>
                    Compliance with eIDAS and GDPR regulations
                  </li>
                </ul>
                <a href="mailto:info@cursorinsight.com?subject=SignoWise Cloud demo request" className="mt-3">
                  <button className="btn">
                    Ask for demo
                    {' '}
                    <img src={arrowBtn} alt="arrow-btn" />
                  </button>
                </a>
                <a
                  href="https://signowise.hu"
                  target="_blank"
                  className="mt-3"
                >
                  Learn more on our Hungarian site
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="solutions__box word">
              <div className="solutions__box--img">
                <img src={swWord} alt="Signo Wise" />
              </div>
              <div className="solution-main">
                <h3>e-signature for large corporates</h3>
              </div>
              <div className="solution-list">
                <ul>
                  <li>
                    Complex, tailored e-signature solution for large
                    institutions
                  </li>
                  <li>
                    Integrates with user management, document management,
                    archiving and other systems
                  </li>
                  <li>
                    Signature process can be configured
                  </li>
                  <li>
                    Compliance with eIDAS and GDPR regulations
                  </li>
                </ul>
                <a href="mailto:info@cursorinsight.com?subject=SignoWise Enterprise demo request" className="mt-3">
                  <button className="btn">
                    Ask for demo
                    {' '}
                    <img src={arrowBtn} alt="arrow-btn" />
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="solutions__box cloud">
              <div className="solutions__box--img">
                <img src={biometric} alt="Signo Wise" />
              </div>
              <div className="solution-main">
                <h3>biometric signature verification</h3>
              </div>
              <div className="solution-list">
                <ul>
                  <li>
                    Award winning handwritten biometric signature verification
                    algorithm
                  </li>
                  <li>
                    Detailed biometrics extracted from
                    movement and pressure data
                  </li>
                  <li>Performs better than human verifiers and competitors</li>
                  <li>Tens of millions of signatures verified</li>
                </ul>
                <a href="mailto:info@cursorinsight.com?subject=Signature Verification demo request" className="mt-3">
                  <button className="btn">
                    Ask for demo
                    {' '}
                    <img src={arrowBtn} alt="arrow-btn" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solutions;
